import { FC } from 'react';
import { Link } from 'react-router-dom';

export interface BreadCrumbLinkProps {
  text: string;
  url: string;
  active?: boolean;
}

const BreadCrumbButton: FC<BreadCrumbLinkProps> = ({ text, url, active = false }) => {
  return (
    <div className="hover:text-color-1 text-dpm-14 inline-flex cursor-pointer items-center rounded-md px-1 py-2 transition-colors duration-150 ease-in-out">
      <Link to={url} className={active ? 'text-color-1 font-medium' : ''}>
        {text}
      </Link>
    </div>
  );
};

export default BreadCrumbButton;
