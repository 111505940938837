import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import useGoBack from '../../../hooks/useGoBack';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';
import { BreadCrumbBackButton } from './BreadCrumbBackButton';
import BreadCrumbButton from './BreadCrumbButton';

type BreadCrumbProps = {
  returnPath?: string;
  breadCrumbs?: BreadCrumbItem[];
  currentStepName: string;
};

export type BreadCrumbItem = {
  name: string;
  path: string;
};

const StaticBreadCrumb: FC<BreadCrumbProps> = (props) => {
  const { returnPath, breadCrumbs, currentStepName } = props;
  const navigate = useNavigate();
  const goBack = useGoBack();

  return (
    <div className="flex items-center rounded-md" data-cy="breadcrumb-bar">
      <BreadCrumbBackButton onClick={() => (returnPath ? navigate(returnPath) : goBack())} />
      <span>
        {breadCrumbs &&
          breadCrumbs.map((breadcrumb, i) => (
            <span key={`crumb-${i}`} data-cy={`breadcrumb-${i}`}>
              <BreadCrumbButton url={breadcrumb.path} text={breadcrumb.name} />
              <ChevronIcon className="inline-flex h-4 w-4" type={ChevronType.RIGHT} />
            </span>
          ))}
        <div className="text-dpm-14 inline-flex items-center px-1 py-2" data-cy="breadcrumb-current-page">
          <span className={'text-color-1 font-medium'}>{currentStepName}</span>
        </div>
      </span>
    </div>
  );
};

export default memo(StaticBreadCrumb);
